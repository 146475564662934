.archive {
    #main {
        background-color: rgb(253, 245, 243) !important;
        color: rgb(78, 78, 78);
    }
    
    #title {
        .paragraph p::after {
            color: rgba(126, 0, 0, 0.692);
            content: " (archive)"
        }
    }

    pre {
        color: rgb(78, 78, 78);
    }

    #archive-warning {
        padding: 1rem;
        margin-bottom: 1rem;
        border: 2px solid darkred;
        font-size: 1.2rem;
    }
}

li {
    .archive {
        opacity: 80%;

        &::after {
            content: " (archive)"
        }
    }
} 
