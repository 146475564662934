body > footer {
  background: #ffffff;
  grid-column: 1 / 3;
  grid-row: 2;
}
footer {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  font-size: 80%;
  max-height: 3rem;

  * {
    vertical-align: middle;
  }

  a {
    display: inline-block;
    background-repeat: no-repeat;
  }

  #footer-left {
    align-self: center;
    justify-self: start;
    padding-left: 3px;
  }

  #footer-center {
    align-self: center;
    justify-self: center;
    text-align: center;
  }

  #footer-right {
    align-self: center;
    justify-self: end;
    padding-right: 3px;
  }
}
