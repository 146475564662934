span.icon > .fa {
    cursor: default;
}
.admonitionblock {
  margin: 1rem;
  width: 90%;
  min-width: MIN(800px, 100%);
  margin-left: auto;
  margin-right: auto;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  td {
    border: solid 1px #dfe2e5;
    padding: 4px;
  }
  td.icon {
    text-align: center;
    width: 80px;
    [class^="fa icon-"] {
      font-size: 2.5em;
      text-shadow: 1px 1px 2px rgba(0,0,0,.5);
      cursor: default;
    }
    .icon-note:before {
      content: "\f05a";
      color: #19407c;
    }
    .icon-tip:before {
      content: "\f0eb";
      text-shadow: 1px 1px 2px rgba(155,155,0,.8);
      color: #111;
    }
    .icon-warning:before {
      content: "\f071";
      color: #bf6900;
    }
    .icon-caution:before {
      content: "\f06d";
      color: #bf3400;
    }
    .icon-important:before {
      content: "\f06a";
      color: #bf0000;
    }
  }
}
