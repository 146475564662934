.marble {
  font-size: 20px;
  white-space:nowrap;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgray;
  padding: 5px 10px;

  ul {
    list-style: none;
    width: fit-content;
    li {
      text-align: left;
      margin: 0.1em 0;
      width: fit-content;
    }
  }

  strong {
    font-size: 20px;
    text-transform: none;
    display: inline-block;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    border-color: black;
    width: 36px;
    margin: 0 0.1em;
    text-align: center;
    font-family: sans-serif;
    color: black;
    &.small {
      font-size: 16px;
    }
    &:nth-of-type(4n+1) {
      background-color: greenyellow;
    }
    &:nth-of-type(4n+2) {
      background-color: gold;
    }
    &:nth-of-type(4n+3) {
      background-color: orangered;
    }
    &:nth-of-type(4n) {
      background-color: lightcyan;
    }
    &.\31, &.\35, &.\39 { // 1, 5, 9 
      background-color: greenyellow;
    }
    &.\32, &.\36, &.\40 { // 2, 6, 10
      background-color: gold;
    }
    &.\33, &.\37, &.\41 { // 3, 7, 11
      background-color: orangered;
    }
    &.\30, &.\34, &.\38 { // 0, 4, 8
      background-color: lightcyan;
    }
  }
  mark {
    color: black;
    background-color: unset;
    font-family: sans-serif;
  }

  .observable {
    text-align: left;

    p {
      display: inline-block;
      background: center repeat-x url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cline x1='0' y1='50' x2='100' y2='50' stroke='black' stroke-width='2' vector-effect='non-scaling-stroke'%3E%3C/line%3E%3C/svg%3E");
      color: transparent;
      font-family: monospace;
      line-height: 36px;
      font-size: 25px;
  
      &::after {
        content: '>';
        position: relative;
        left: 4px;
        color: black;
        font-family: sans-serif;
        font-size: 20px;
      }
    }
  }

  
  .operator  {
    border: 1px solid black;
    padding: 1em;
    p {
      text-align: center;
    }
  }

}
