@mixin w($width) {
  .content {
    min-width: MIN(800px, 100%);
    width: concat($width, '%');
    max-width: concat($width, 'rem');
  }
}
@mixin center-block() {
  .content {
    margin-left: auto;
    margin-right: auto;
  }
}

@function concat($num, $unit) {
  @return #{$num}#{$unit};
}

$widths: (
  40: 0,
  50: 0,
  60: 0,
  70: 0,
  80: 0,
  90: 0,
  95: 0,
  100: 0
);

@each $state, $nothing in $widths {
  .w-#{$state} {
    @include w($state)
  }
  .wc-#{$state} {
    @include w($state);
    @include center-block();
  }
}

.fc {
  width: fit-content;
  max-width: 100%;

  .content {
    width: fit-content;
    max-width: 100%;
  }
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.bg_lightgreen {
  background-color: rgb(226, 247, 226);
  pre {
    background-color: rgb(226, 247, 226);
  }
}
