#toc-container {
  margin: 0.1rem;
  padding: 0 1rem 0 0.5rem;
  font-size: 90%;
  float:right;
  background-color: #f8f9fa; 
  border: 1px solid #aaa; 

  h3 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    font-size: 100%;
  }

  &.depth-1 {
    .toc-h3 {
      display: none;
    }
  }
}