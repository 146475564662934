.center {
    width: 100%;
    text-align: center;
}
div.floatleft {
    float: left;
    clear: left;
    position: relative;
    margin: 0 .5em .5em 0;
    border: 0;
}
div.floatright {
    float: right;
    clear: right;
    position: relative;
    margin: 0 .5em .5em 0;
    border: 0;
}
.col-3 {
    columns: 3;
    li {
        break-inside: avoid;
    }
}
.col-4 {
    columns: 4;
    li {
        break-inside: avoid;
    }
}
.small {
    font-size: 80%;
}
.mt-1 {
    margin-top: 1rem;
}
.mt-2 {
    margin-top: 2rem;
}
.mb-1 {
    margin-bottom: 1rem;
}
.mb-2 {
    margin-bottom: 2rem;
}
.mb-3 {
    margin-bottom: 3rem;
}
.mb-4 {
    margin-bottom: 4rem;
}
.clear {
    clear: both;
}
.width-80 {
    min-width: MIN(800px, 100%);
    width: 80%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
}
.width-60 {
    min-width: MIN(600px, 100%);
    width: 60%;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
}
.width-50 {
    min-width: MIN(500px, 100%);
    width: 50%;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
}
.width-40 {
    min-width: MIN(400px, 100%);
    width: 40%;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
}
.width-30 {
    min-width: MIN(300px, 60%);
    width: 30%;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
}
.big {
    font-size: 1.5em;
}
.text-black {
    color: #000;
    code {
        color: #000;
    }
}
.text-grey {
    color: #666;
    code {
        color: #666;
    }
}
.line-through {
    text-decoration: line-through;
}
.underline {
    text-decoration: underline;
}

.references {
    border-top: solid 1px #525252;
    margin-top: 1rem;
    padding-top: 0.5rem;
    p {
      margin-bottom: 0.1rem;
    }
  }
  
a[target="_blank"]::after {
    --icon-size: 0.7em;
    background-image: url(/img/common/link-icon.svg);
    background-size: var(--icon-size);
    background-position: right;
    background-repeat: no-repeat;
    display: inline-block;
    width: calc(var(--icon-size) * 1.2);
    height: var(--icon-size);
    content: "";
}

#wip-warning {
    padding: 1rem;
    margin-bottom: 1rem;
    border: 2px solid darkred;
    background-color: rgb(253, 245, 243) !important;
    font-size: 1.2rem;
}

#tag-content:not(:empty) {
    padding-bottom: 1rem;
    border-bottom: 1px solid #aaa;
}
