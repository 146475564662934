div.ulist.uml {
  &.center {
    ul {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.interface ul {
    background-color: #f9f1c2;
  }
  &.interface > ul > li {
    --icon-size: 1em;
    background-image: url(/img/common/interface-icon.svg);
    background-size: var(--icon-size);
    background-position: right calc(var(--icon-size) / 4) top calc(var(--icon-size) / 4);
    background-repeat: no-repeat;
    & > p {
      padding-right: 1rem;
    }
  }
  ul {
    font-size: 92%;
    background-color: #e8f6fd;
    border: solid 0.1rem rgb(95, 95, 95);
    width: max-content;
    max-width: 100%;
    margin: 1rem;
    -webkit-padding-start: 0;
    padding: 0;
    list-style-image: unset;

    li {
      list-style-type: none;
      text-align: center;
      margin: 0;

      p {
        margin: 0.2rem 0.5rem;
      }
      a {
        margin: 0.2rem 0.5rem;
        color: black;
      }
      div.ulist {
        width: 100%;

        ul {
          padding: 0;
          margin: 0;
          width: 100%;
          border: unset;

          li {
            text-align: left;
            list-style-type: none;
            margin: 0;
            text-indent: -1rem;
            padding: 0 0.2rem 0 1rem;
            &:first-child {
              border-top: solid 0.1rem grey;
            }
            &:has(.line) {
              border-bottom: solid 0.1rem grey;
            }
          }
        }
      }
    }
  }
  .static {
    text-decoration: underline
  }
}

.inline {
  display: flex;
  div.ulist.uml {
    display: inline-flex;
  }
}
.center {
  .content {
    margin-left: auto;
    margin-right: auto;
  }
}