body {
  font-family: sans-serif;
  background: #acabab;
  color: black;
  font-size: 1rem;
  line-height: 1.4rem;
}

#main {
  background: #ffffff;
  border-left: #acabab solid 2px;
  padding: 1em;
  z-index: 0;
}

#menu {
  #logo {
    a {
      display: block;
      height: 155px;
      width: 100%;
      background-repeat: no-repeat;
      background-position: 35% 50% !important;
      text-decoration: none;
    }
  }

  .portlet {
    display: grid;
    font-size: 90%;
  
    ul.section-nav, // toc has no portlet-body
    .portlet-body {
      background: #ffffff;
      border: 1px solid #aaa;
      grid-row: 2;
      padding-right: 0.2em;
    }
    
    h3 {
      font-size: 90%;
      font-weight: normal;
      margin: 1em 0 0 0;
      padding-left: 0.3em;
      grid-row: 1;
    }
  
    ul:empty,
    ul:empty ~ h3 {
      display: none;
      height: 0;
      margin: 0;
      overflow: hidden;
      grid-row: 2;
    }
  }
  
}

h1, h2 {
  border-bottom: 1px solid #aaa;
  margin: 1em 0 0.5em 0;
  font-weight: normal;
  line-height: 2.5rem;
}
h3, h4, h5, h6 {
  margin: 1em 0 0.5em 0;
  font-weight: bold;
}

a {
  text-decoration: none;
  color: #0645ad;
  background: none;
}

#title {
  font-weight: bold;
  font-size: 210%;
  margin-bottom: 0.5em;

  a {
    color: #424242;
  }
}

code {
  color: #920000;
}

pre, code {
  font-family: monospace;

  a {
    color: #5a5a5a;
  }
}
.listingblock {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  .title {
    font-style: italic;
  }
}
pre {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 0.5rem;
  margin: 0;
  // white-space: pre-wrap;
  // word-wrap: break-word;
  overflow: auto;
  color: black;
  code {
    color: inherit;
    .comment {
      color: purple;
    }
    .error {
      color: rgb(179, 92, 92);
    }
  }
}

.center {
  width: 100%;
  text-align: center;
}
img, embed, object, video {
  max-width: 100%;
  height: auto;
}

.columns {
  -webkit-columns: 3;
  -moz-columns: 3;
  columns: 3;
  width: 100%;

  ul {
    margin: 0;
  }
}

ul {
  line-height: 1.5em;
  // font-size: 95%;
  list-style-type: square;
  list-style-image: url(data:image/gif;base64,R0lGODlhBQANAIAAAGOMnP///yH5BAEAAAEALAAAAAAFAA0AAAIJjI+pu+APo4SpADs=);
  padding: 0.1em 0 0 MIN(10%, 1.5em);
  margin: 0;
}
li {
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

.paragraph {
  margin: 0.5em 0 0.1em 0;
}

#footnotes {
  column-width: 20rem;
  border-top: 1px solid #aaa;
  margin-top: 1rem;
  padding-top: 0.1rem;
  hr {
    display: none;
  }
  .footnote {
    font-size: 0.8rem;
    line-height: 1.1rem;
  }
}