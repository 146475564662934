table {
  border-collapse: collapse;
  word-wrap: normal;

  &.tableblock {
    background: #fff;
    background: var(--body-background);
    font-size: .83333rem;
    font-size: calc(15/var(--rem-base)*1rem);
    margin: 1.5rem 0 0
  }
  &.tableblock+* {
    margin-top: 1.5rem
  }
  &.tableblock,
  &.tableblock>*>tr>* {
    border: 0 solid #e1e1e1;
  }
  &.tableblock td,
  &.tableblock th {
    padding: .5rem
  }
  &.grid-all>*>tr>* {
    border-width: 1px
  }
  &.grid-cols>*>tr>* {
    border-width: 0 1px
  }
  &.grid-rows>*>tr>* {
    border-width: 1px 0
  }
  &.grid-all>thead th,
  &.grid-rows>thead th {
    border-bottom-width: 2.5px
  }
}
td.tableblock>.content>:first-child {
  margin-top: 0
}
